<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <vx-card title="Credit Note Claim Mix">
    <div class="flex flex-col gap-3 w-full">
      <div class="flex flex-col w-full">
        <label class="vs-input--label">Customer</label>
        <multiselect
          v-validate="'required'"
          name="Customer"
          class="selectExample"
          v-model="create.customer"
          :options="option.customer"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          track-by="ID"
          :custom-label="queryCustomer"
          @search-change="onCustomerSearchChange"
          :disabled="isEdit || viewOnly"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                `(${dt.option.code}), ${dt.option.name}`
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                `(${dt.option.code}), ${dt.option.name}`
              }}</span>
            </div>
          </template>
        </multiselect>
        <span v-show="errors.has('Customer')" class="text-danger">
          {{ errors.first("Customer") }}
        </span>
      </div>
      <vx-card class="bg-primary text-white font-bold" v-if="selectedCustomer">
        <div class="flex flex-col w-full gap-1">
          <!-- Customer : ({{ selectedCustomer.customer.code }}) -->
          <p>
            Customer : ({{ selectedCustomer.customer.code }}) -
            <span>{{ selectedCustomer.customer.name }}</span>
          </p>
          <p>
            Customer Channel : ({{ selectedCustomer.customer_category.name }}
            <span>{{ selectedCustomer.customer_category.classification }} </span
            >)
          </p>
          <p>
            Customer Tax Number :
            {{ selectedCustomer.customer.npwp ? selectedCustomer.npwp : "-" }}
          </p>
          <p>
            Customer Tax Name :
            {{
              selectedCustomer.customer.npwp_name
                ? selectedCustomer.customer.npwp_name
                : "-"
            }}
          </p>
          <p>
            Customer Tax Type :
            {{
              selectedCustomer.customer.npwp_type
                ? selectedCustomer.customer.npwp_type
                : "-"
            }}
          </p>
          <p>
            WH Tax Calculation :
            {{ this.withholdingTax ? this.withholdingTax : "-" }}
          </p>
        </div>
      </vx-card>

      <div class="flex gap-6">
        <div class="flex flex-col w-full">
          <label class="vs-input--label">Territory</label>
          <multiselect
            v-validate="'required'"
            name="Territory"
            class="selectExample"
            v-model="create.territory"
            :options="option.territory"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder=" Type to search"
            track-by="TerritoryID"
            :custom-label="queryTerritory"
            :disabled="
              viewOnly ||
              (selectedCustomer &&
                selectedCustomer.customer_category.name === 'GT')
            "
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title">{{
                  dt.option.TerritoryCode + " " + dt.option.TerritoryName
                }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title">{{
                  dt.option.TerritoryCode + " " + dt.option.TerritoryName
                }}</span>
              </span>
            </template>
          </multiselect>
          <span v-show="errors.has('Territory')" class="text-danger">
            {{ errors.first("Territory") }}
          </span>
        </div>
        <div class="flex flex-col w-full">
          <label class="vs-input--label">Operating Unit</label>
          <multiselect
            v-validate="'required'"
            name="Operating Unit"
            v-model="create.operatingUnit"
            :options="option.operatingUnit"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder=" Type to search"
            track-by="ID"
            label="Name"
            :disabled="viewOnly || create.customer !== null"
            :custom-label="queryOperatingUnit"
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title">{{
                  dt.option.Code + " " + dt.option.Name
                }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title">{{
                  dt.option.Code + " " + dt.option.Name
                }}</span>
              </span>
            </template>
          </multiselect>
          <span v-show="errors.has('Operating Unit')" class="text-danger">
            {{ errors.first("Operating Unit") }}
          </span>
        </div>
      </div>

      <div class="flex gap-6">
        <div class="flex flex-col w-full">
          <label class="vs-input--label">Ownership</label>
          <multiselect
            v-validate="'required'"
            name="Ownership"
            v-model="create.ownership"
            :options="option.ownership"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder=" Type to search"
            track-by="id"
            :disabled="
              viewOnly ||
              (selectedCustomer &&
                selectedCustomer.customer_category.name === 'GT')
            "
            label="name"
            @select="onOwnershipSelected"
          >
            <!-- <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title">{{
                  "(" + dt.option.Code + ") " + dt.option.Name
                }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title">{{
                  "(" + dt.option.Code + ") " + dt.option.Name
                }}</span>
              </div>
            </template> -->
          </multiselect>
          <span v-show="errors.has('Ownership')" class="text-danger">
            {{ errors.first("Ownership") }}
          </span>
        </div>

        <div class="flex flex-col w-full">
          <label class="vs-input--label">Debit Note</label>
          <multiselect
            v-model="create.debitNote"
            :options="option.debitNote"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="120"
            :limit="3"
            placeholder="Type to search"
            track-by="ID"
            label="code"
            @search-change="onDebitNoteSearchChange"
            :custom-label="queryDebitNote"
            :disabled="viewOnly"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.code }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.code }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>

      <div class="flex gap-6">
        <div class="flex flex-col w-full">
          <label class="vs-input--label">CN Date</label>
          <datepicker
            class="w-full"
            v-validate="'required'"
            :inline="false"
            name="CN Date"
            v-model="create.cnDate"
            placeholder="CN Date"
            :disabled="viewOnly"
            :disabled-dates="{ from: new Date() }"
          />
          <span v-show="errors.has('CN Date')" class="text-danger">
            {{ errors.first("CN Date") }}
          </span>
        </div>
        <div class="flex flex-col w-full">
          <label class="vs-input--label">CN Posting Date</label>
          <datepicker
            class="w-full"
            v-validate="'required'"
            :inline="false"
            name="CN Posting Date"
            v-model="create.cnPostingDate"
            placeholder="CN Posting Date"
            :disabled-dates="{ from: new Date() }"
            :disabled="viewOnly"
          />
          <span v-show="errors.has('CN Posting Date')" class="text-danger">
            {{ errors.first("CN Posting Date") }}
          </span>
        </div>
      </div>

      <div class="flex gap-6">
        <div class="flex flex-col w-full">
          <label class="vs-input--label">Tax Invoice Date</label>
          <datepicker
            class="w-full"
            v-validate="create.isVat === 1 ? 'required' : null"
            :inline="false"
            name="Tax Invoice Date"
            v-model="create.taxReturnDate"
            placeholder="Tax Invoice Date"
            :disabled-dates="{ from: new Date() }"
            :disabled="viewOnly"
          />
          <span v-show="errors.has('Tax Invoice Date')" class="text-danger">
            {{ errors.first("Tax Invoice Date") }}
          </span>
        </div>
        <div class="flex flex-col w-full">
          <label class="vs-input--label">Tax Invoice Number</label>
          <vs-input
            v-validate="create.isVat == 1 ? 'required' : null"
            name="Tax Invoice Number"
            class="w-full"
            v-model="create.taxReturnNumber"
            @keypress="isNumberAndFormat($event)"
            :disabled="viewOnly"
          />
          <span
            v-show="errors.has('Tax Invoice Number')"
            class="text-danger vs-input--label"
          >
            {{ errors.first("Tax Invoice Number") }}
          </span>
        </div>
      </div>

      <div class="flex gap-6">
        <div class="flex flex-col sm:w-1/2 w-full">
          <label class="vs-input--label">SKB</label>
          <div
            class="flex gap-3 border border-solid border-grey rounded-lg p-3"
          >
            <vs-radio v-model="create.skb" vs-name="radios1" vs-value="false"
              >No</vs-radio
            >
            <vs-radio v-model="create.skb" vs-name="radios1" vs-value="true"
              >Yes</vs-radio
            >
          </div>
          <span v-show="errors.has('Tax Invoice Date')" class="text-danger">
            {{ errors.first("Tax Invoice Date") }}
          </span>
        </div>
        <div class="flex flex-col sm:w-1/2 w-full" />
      </div>

      <div
        class="flex flex-col gap-1 mt-3"
        style="width: 100%; padding-bottom: 15px"
      >
        <span>Reference</span>
        <vs-textarea
          class="w-full"
          name="Reference"
          v-model="create.notes"
          placeholder="Reference"
          :disabled="viewOnly"
        />
      </div>
      <vs-divider style="width: 100%">Attachment</vs-divider>
      <div class="vx-row mb-3 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span>File</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <input
              id="fileInput"
              name="file"
              class="w-full inputx"
              type="file"
              ref="file"
              multiple="multiple"
              accept=".jpg, .jpeg, .png, .pdf"
              :disabled="viewOnly"
            />
          </div>
        </div>
      </div>
      <div class="vx-row mb-3 w-4/4" style="width: 100%; margin-left: 0%">
        <div class="vx-col sm:w-1/3 w-full">
          <span></span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <div class="vx-col sm:w-4/5 w-full">
            <vs-button
              :disabled="viewOnly"
              class="mr-3 mb-2"
              @click="handleAttachment"
              >Add Attachment</vs-button
            >
          </div>
        </div>
      </div>
      <vs-divider style="width: 100%">List Attachment</vs-divider>
      <div
        v-for="(tr, i) in fileAttachment"
        class="flex gap-3 items-center mb-6"
        :key="i"
      >
        <div class="sm:w-1/3">
          <span>{{ tr.NameFile }}</span>
        </div>
        <vx-tooltip text="Show" style="width: fit-content">
          <vs-button
            @click="handleShow(tr)"
            icon-pack="feather"
            icon="icon-eye"
            color="primary"
            type="line"
            :disabled="viewOnly"
          />
        </vx-tooltip>

        <vx-tooltip text="Remove" style="width: fit-content">
          <vs-button
            @click="handleDeleteAttachment(i)"
            icon-pack="feather"
            icon="icon-trash"
            color="danger"
            type="line"
            :disabled="viewOnly"
          />
        </vx-tooltip>
      </div>
      <vs-divider style="width: 100%">PPh Value</vs-divider>
      <div class="flex gap-3">
        <div class="flex flex-col sm:w-1/2 w-full">
          <label class="vs-input--label">Total CN Value</label>
          <vs-input
            v-validate="'required|notZero'"
            name="CN Value"
            class="w-full"
            v-model="create.amount"
            @keyup="onCnValueKeyUp"
            @blur="onCnValueBlur"
            :disabled="viewOnly"
          />
          <span v-show="errors.has('CN Value')" class="text-danger">
            {{ errors.first("CN Value") }}
          </span>
        </div>
        <div class="flex flex-col sm:w-1/2 w-full">
          <label class="vs-input--label">Total PPh Value</label>
          <vs-input
            v-validate="'required|notZero'"
            name="Total PPh"
            class="w-full"
            v-model="pphTotal"
            readonly
            :disabled="viewOnly"
          />
          <span v-show="errors.has('Total PPh')" class="text-danger">
            {{ errors.first("Total PPh") }}
          </span>
        </div>
      </div>

      <div class="flex gap-3">
        <div class="flex flex-col sm:w-1/2 w-full">
          <label class="vs-input--label">Total Detail Value</label>
          <vs-input
            v-validate="'required|equalToCnTotal|notZero'"
            readonly
            name="Total Detail Value"
            class="w-full"
            :disabled="viewOnly"
            v-model="cnDetailValueTotal"
          />
          <span v-show="errors.has('Total Detail Value')" class="text-danger">
            {{ errors.first("Total Detail Value") }}
          </span>
        </div>
        <div class="flex flex-col sm:w-1/2 w-1/2">
          <label class="vs-input--label">Difference</label>
          <vs-input
            readonly
            name="Total Detail Value"
            class="w-full"
            :disabled="viewOnly"
            v-model="differenceOfDetailAndCnValue"
          />
        </div>
      </div>

      <div class="w-full">
        <vs-divider />
      </div>

      <div class="flex justify-between items-center w-1/2">
        <label class="vs-input--label font-bold">VAT IN</label>
        <vs-switch
          v-model="create.isVat"
          vs-label="VAT IN"
          :disabled="viewOnly"
        />
      </div>

      <div class="flex gap-3">
        <div class="flex flex-col w-1/2">
          <label class="vs-input--label">Tax Rate</label>
          <multiselect
            v-validate="create.isVat ? 'required' : null"
            v-model="create.tax"
            :options="option.tax"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="120"
            :limit="3"
            placeholder="Type to search"
            track-by="ID"
            label="Name"
            name="Tax Rate"
            @search-change="onTaxSearchChange"
            :disabled="viewOnly || !create.isVat"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title">{{ props.option.Name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.Name }}</span>
              </div>
            </template>
          </multiselect>
          <span v-show="errors.has(`Tax Rate`)" class="text-danger">
            {{ showErrorWithoutNumber(errors.first(`Tax Rate`)) }}
          </span>
        </div>
        <div class="flex flex-col sm:w-1/2 w-full mb-8">
          <label class="vs-input--label">Total VAT</label>
          <vs-input
            v-validate="create.isVat ? 'required|notZero' : null"
            :name="'VAT'"
            class="w-full"
            @keyup="onVatTotalKeyUp"
            @blur="onVatTotalBlur"
            v-model="create.vatTotal"
            :disabled="viewOnly || !create.isVat"
          />

          <span v-show="errors.has(`VAT`)" class="text-danger">
            {{ showErrorWithoutNumber(errors.first(`VAT`)) }}
          </span>
        </div>
      </div>

      <div class="w-full">
        <vs-divider />
      </div>

      <div class="flex justify-start items-center w-1/2 mb-4">
        <vs-checkbox
          :disabled="viewOnly"
          v-model="create.isHO"
          @input="onHoChecboxChange"
          ><label class="font-bold text-lg">HO</label></vs-checkbox
        >
      </div>

      <vs-card
        v-for="(line, i) in lines"
        :key="'line' + i.toString()"
        class="flex flex-col gap-3"
      >
        <div slot="header">
          <div class="flex w-full justify-end">
            <vx-tooltip text="Remove" style="width: fit-content">
              <vs-button
                :disabled="lines.length <= 1 || viewOnly"
                type="border"
                color="danger"
                size="small"
                icon="icon-x"
                iconPack="feather"
                @click="removeLine(i)"
              />
            </vx-tooltip>
          </div>
        </div>
        <div class="flex flex-col gap-3">
          <div class="flex gap-3" style="width: 100%">
            <div class="flex flex-col w-full">
              <label class="vs-input--label">Ship To</label>
              <multiselect
                v-validate="
                  create.isHO ||
                  (create.territory &&
                    create.territory.TerritoryID === HO_TERRITORY_ID)
                    ? null
                    : 'required'
                "
                :name="'Ship To ' + i"
                v-model="line.ship_to"
                :options="option.shipTo"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="160"
                :limit="4"
                placeholder=" Type to search"
                :custom-label="QueryShipTo"
                :disabled="
                  viewOnly ||
                  create.isHO ||
                  (create.territory &&
                    create.territory.TerritoryID === HO_TERRITORY_ID)
                "
              >
                <template slot="singleLabel" slot-scope="dt">
                  <span class="option__desc">
                    <span class="option__title">{{
                      `(${dt.option.code} - ${getTerritoryName(
                        dt.option.territory_id
                      )}) ${dt.option.contact_name}`
                    }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="dt">
                  <div class="option__desc">
                    <div class="option__title">
                      <p>
                        {{ `(${dt.option.code}) ${dt.option.contact_name}` }}
                      </p>
                      <div class="w-full text-wrap" style="text-wrap: balance">
                        {{
                          `${dt.option.address}, ${dt.option.sub_district}, ${dt.option.district}, ${dt.option.city}`
                        }}
                      </div>
                    </div>
                  </div>
                </template>
              </multiselect>
              <span v-show="errors.has(`Ship To ${i}`)" class="text-danger">
                {{ showErrorWithoutNumber(errors.first(`Ship To ${i}`)) }}
              </span>
            </div>
            <div class="flex flex-col w-full">
              <label class="vs-input--label">CN Promotion Type</label>
              <multiselect
                class="selectExample"
                v-validate="'required'"
                :name="'CN Promotion Type ' + i"
                v-model="lines[i].promotion_type"
                :options="option.cnPromotionType"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="160"
                :limit="4"
                placeholder=" Type to search"
                :disabled="viewOnly"
                @select="onPromotionSelected($event, i)"
              >
                <template slot="singleLabel" slot-scope="dt">
                  <span class="option__desc">
                    <span class="option__title">{{ dt.option.Name }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="dt">
                  <div class="option__desc">
                    <span class="option__title">{{ dt.option.Name }}</span>
                  </div>
                </template>
              </multiselect>
              <vx-card
                class="bg-primary text-white font-bold mt-4"
                v-if="lines[i].promotion_type != null"
              >
                <div
                  v-for="(rate, i) in lines[i].promotion_type.RateOption"
                  :key="'whTax' + i.toString()"
                >
                  <span>
                    Withholding Tax Rate {{ rate.NpwpType }} : {{ rate.Rate }} %
                  </span>
                </div>

                <template> WH Tax Calculation : {{ withholdingTax }} </template>
              </vx-card>
              <span
                v-show="errors.has(`CN Promotion Type ${i}`)"
                class="text-danger"
              >
                {{
                  showErrorWithoutNumber(errors.first(`CN Promotion Type ${i}`))
                }}
              </span>
            </div>
          </div>

          <div class="flex gap-3 w-full" style="width: 100%">
            <div class="flex flex-col w-full">
              <label class="vs-input--label">Text</label>
              <vs-input
                v-validate="'required'"
                class="w-full"
                v-model="line.text"
                :name="'Text ' + i"
                :disabled="viewOnly"
              />
              <span v-show="errors.has(`Text ${i}`)" class="text-danger">
                {{ showErrorWithoutNumber(errors.first(`Text ${i}`)) }}
              </span>
            </div>
            <div class="flex flex-col w-full">
              <label class="vs-input--label">Assignment</label>
              <vs-input
                v-validate="'required'"
                class="w-full"
                v-model="line.assignment"
                :name="'Assignment ' + i"
                :disabled="viewOnly"
              />
              <span v-show="errors.has(`Assignment ${i}`)" class="text-danger">
                {{ showErrorWithoutNumber(errors.first(`Assignment ${i}`)) }}
              </span>
            </div>
          </div>

          <div class="flex gap-3" style="width: 100%">
            <div class="flex flex-col sm:w-1/3 w-full">
              <label class="vs-input--label">DPP</label>
              <vs-input
                v-validate="'required|notZero'"
                :name="'DPP ' + i"
                class="w-full"
                v-model="line.dpp"
                @keyup="onDppKeyUp(i)"
                @blur="onDppFieldBlur(i)"
                @input="onDppChange($event, i)"
                :disabled="viewOnly"
              />
              <span v-show="errors.has(`DPP ${i}`)" class="text-danger">
                {{ showErrorWithoutNumber(errors.first(`DPP ${i}`)) }}
              </span>
            </div>
            <div class="flex flex-col sm:w-1/3 w-full">
              <label class="vs-input--label">VAT Line</label>
              <vs-input
                v-validate="create.isVat ? 'required|notZero' : null"
                :name="`Value ${i}`"
                class="w-full"
                readonly
                v-model="line.vat"
                :disabled="viewOnly"
              />
              <span v-show="errors.has(`Value ${i}`)" class="text-danger">
                {{ showErrorWithoutNumber(errors.first(`Value ${i}`)) }}
              </span>
            </div>
            <div class="flex flex-col sm:w-1/3 w-full">
              <label class="vs-input--label">Value</label>
              <vs-input
                v-validate="'required|notZero'"
                :name="`Value ${i}`"
                class="w-full"
                readonly
                v-model="line.value"
                :disabled="viewOnly"
              />
              <span v-show="errors.has(`Value ${i}`)" class="text-danger">
                {{ showErrorWithoutNumber(errors.first(`Value ${i}`)) }}
              </span>
            </div>
            <div class="flex flex-col sm:w-1/3 w-full">
              <label class="vs-input--label">PPh Value</label>
              <vs-input
                v-validate="'required|notZero'"
                :name="'PPh Value ' + i"
                class="w-full"
                readonly
                v-model="line.withholding_tax"
                :disabled="viewOnly"
              />
              <span v-show="errors.has(`PPh Value ${i}`)" class="text-danger">
                {{ showErrorWithoutNumber(errors.first(`PPh Value ${i}`)) }}
              </span>
            </div>
          </div>
        </div>
      </vs-card>

      <div class="flex w-full">
        <div>
          <vs-button
            iconPack="feather"
            icon="icon-plus"
            type="border"
            @click="addLine"
            :disabled="viewOnly"
            >Add PPh Value</vs-button
          >
        </div>
      </div>

      <vs-divider style="width: 100%">COA</vs-divider>

      <vs-card v-for="(line, k) in coaLines" :key="'coa' + k">
        <div slot="header">
          <div class="flex w-full justify-end">
            <vx-tooltip text="Remove" style="width: fit-content">
              <vs-button
                type="border"
                color="danger"
                size="small"
                icon="icon-x"
                iconPack="feather"
                @click="removeCoaLine(k)"
                :disabled="viewOnly"
              />
            </vx-tooltip>
          </div>
        </div>
        <div class="flex flex-col gap-3 w-full">
          <div class="flex gap-3">
            <div class="w-full flex flex-col">
              <label class="vs-input--label">COA</label>
              <multiselect
                v-validate="'required'"
                :name="`COA ${k}`"
                v-model="line.coa"
                :options="option.coa"
                :multiple="false"
                :allow-empty="true"
                :group-select="false"
                :max-height="160"
                :limit="4"
                placeholder=" Type to search"
                track-by="ID"
                :custom-label="onQueryCoa"
                @search-change="onCoaSearchChanges"
                :disabled="viewOnly"
              >
                <template slot="singleLabel" slot-scope="dt">
                  <span class="option__desc">
                    <span class="option__title"
                      >{{ dt.option.Code }} - {{ dt.option.Name }}</span
                    >
                  </span>
                </template>

                <template slot="option" slot-scope="dt">
                  <div class="option__desc">
                    <span class="option__title"
                      >{{ dt.option.Code }} - {{ dt.option.Name }}</span
                    >
                  </div>
                </template>
              </multiselect>
              <span v-show="errors.has(`COA ${k}`)" class="text-danger">
                {{ showErrorWithoutNumber(errors.first(`COA ${k}`)) }}
              </span>
            </div>

            <div class="w-full flex flex-col">
              <label class="vs-input--label">Cost Center</label>
              <multiselect
                class="selectExample"
                v-validate="'required'"
                :name="`Cost Center ${k}`"
                v-model="line.cost_center"
                :options="option.costCenter"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="160"
                :limit="4"
                placeholder=" Type to search"
                label="Name"
                :disabled="viewOnly"
              >
                <template slot="singleLabel" slot-scope="dt">
                  <span class="option__desc">
                    <span class="option__title">{{
                      "(" + dt.option.Code + ") " + dt.option.Name
                    }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="dt">
                  <div class="option__desc">
                    <span class="option__title">{{
                      "(" + dt.option.Code + ") " + dt.option.Name
                    }}</span>
                  </div>
                </template>
              </multiselect>
              <span v-show="errors.has(`Cost Center ${k}`)" class="text-danger">
                {{ showErrorWithoutNumber(errors.first(`Cost Center ${k}`)) }}
              </span>
            </div>
          </div>

          <div class="flex gap-3">
            <div class="w-full flex flex-col">
              <label class="vs-input--label">Territory</label>
              <multiselect
                class="selectExample"
                v-validate="'required'"
                :name="`Territory ${k}`"
                v-model="line.territory"
                :options="option.territoryCoa"
                :multiple="false"
                :allow-empty="false"
                :group-select="false"
                :max-height="160"
                :limit="4"
                placeholder=" Type to search"
                track-by="TerritoryID"
                label="TerritoryName"
                :disabled="viewOnly"
              >
                <template slot="singleLabel" slot-scope="dt">
                  <span class="option__desc">
                    <span class="option__title">{{
                      dt.option.TerritoryCode + " " + dt.option.TerritoryName
                    }}</span>
                  </span>
                </template>

                <template slot="option" slot-scope="dt">
                  <span class="option__desc">
                    <span class="option__title">{{
                      dt.option.TerritoryCode + " " + dt.option.TerritoryName
                    }}</span>
                  </span>
                </template>
              </multiselect>
              <span v-show="errors.has(`Territory ${k}`)" class="text-danger">
                {{ showErrorWithoutNumber(errors.first(`Territory ${k}`)) }}
              </span>
            </div>

            <div class="w-full flex flex-col">
              <label class="vs-input--label">Value</label>
              <vs-input
                class="w-full"
                v-validate="'required'"
                :name="`Value ${k}`"
                v-model="line.value"
                @keyup="line.value = formatPrice(line.value.toString())"
                @blur="onCoaBlur(k)"
                :disabled="viewOnly"
              />
              <span v-show="errors.has(`Value ${k}`)" class="text-danger">
                {{ showErrorWithoutNumber(errors.first(`Value ${k}`)) }}
              </span>
            </div>
          </div>
        </div>
      </vs-card>

      <div class="flex w-full">
        <div>
          <vs-button
            iconPack="feather"
            icon="icon-plus"
            type="border"
            @click="addCoaLine"
            :disabled="viewOnly"
            >Add COA</vs-button
          >
        </div>
      </div>
      <vs-prompt
        v-validate="'required'"
        title="Confirmation"
        color="danger"
        @accept="handleReverse"
        @cancel="handleClose"
        @close="handleClose"
        :buttons-hidden="false"
        :active.sync="showApprovalPrompt"
      >
        <div class="flex flex-col gap-4">
          <p>
            Are you sure you want to reverse
            <span class="font-bold">{{ creditNote.Code }}?</span>
          </p>
          <!-- <div class="flex flex-col gap-1">
                <span>Reversal date</span>
                <datepicker
                  class="w-full"
                  v-validate="'required'"
                  :inline="false"
                  v-model="reversalDate"
                  placeholder="Date Clearing"
                  name="reversal date"
                  :disabled-dates="{ from: new Date() }"
                />
                <span
                  v-show="errors.has('reversal date')"
                  class="text-danger text-sm help is-danger"
                >
                  {{ errors.first("reversal date") }}
                </span>
              </div> -->
          <div class="flex flex-col gap-1">
            <span>Reversal Note</span>
            <vs-textarea v-model="note_reversal" />
          </div>
        </div>
      </vs-prompt>
      <vs-popup
        title="Codes"
        button-close-hidden
        :active.sync="cnCodePopup"
        :closeOnBackdrop="false"
        ref="popup"
      >
        <div class="w-full flex flex-col items-end gap-6">
          <div class="flex gap-3 items-end w-full">
            <div class="flex flex-col gap-1 w-full">
              <span class="font-bold">Credit Note Codes</span>
              <vs-input v-model="createdCodes" class="w-full" readonly />
            </div>
            <vs-button
              iconPack="feather"
              icon="icon-file"
              @click="copyToClipboard(createdCodes)"
              type="border"
              >Copy</vs-button
            >
          </div>
          <div class="flex gap-3 items-end w-full">
            <div class="flex flex-col gap-1 w-full">
              <span class="font-bold">PPh Invoice Codes</span>
              <vs-input v-model="createdInvoiceCodes" class="w-full" readonly />
            </div>
            <vs-button
              iconPack="feather"
              icon="icon-file"
              @click="copyToClipboard(createdInvoiceCodes)"
              type="border"
              >Copy</vs-button
            >
          </div>
          <div class="mt-8">
            <vs-button color="danger" @click="close">Close</vs-button>
          </div>
        </div>
      </vs-popup>
      <div class="flex w-full justify-end mt-5 gap-3">
        <div>
          <vs-button @click="navigateBack" color="danger"> Close </vs-button>
        </div>
        <div v-if="!viewOnly">
          <vs-button @click="handleSubmit(false)" :disabled="viewOnly">
            Save
          </vs-button>
        </div>
        <div v-if="!viewOnly">
          <vs-button @click="handleSubmit(true)" :disabled="viewOnly">
            Save & Release
          </vs-button>
        </div>
        <div v-if="viewOnly && creditNote.Status === 4">
          <vs-button color="danger" @click="handleOpenPrompt" type="border">
            Reverse
          </vs-button>
        </div>
      </div>
    </div>
  </vx-card>
</template>
<script>
import { getDataCustomer } from "../../../../services/api/payment";
import { createCreditNoteManual } from "../../../../services/api/credit_note";
// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import debounce from "debounce";
export default {
  components: {
    // flatPickr,
    Datepicker,
  },
  props: {
    selected: Object,
  },
  data() {
    return {
      fileAttachment: [],
      file: [],
      fileTemp: [],
      creditNote: {},
      create: {
        id: 0,
        customer: null,
        territory: null,
        operatingUnit: null,
        cnDate: null,
        cnPromotionType: null,
        cnPostingDate: null,
        debitNote: null,
        customer_code: "",
        costCenter: null,
        vat: 0,
        amount: 0,
        taxReturnNumber: null,
        taxReturnDate: "",
        notes: "",
        dpp: 0,
        withholdingTaxValue: 0,
        vatInValue: 0,
        ownership: null,
        tax: null,
        isVat: false,
        skb: "false",
        vatTotal: "",
        isHO: true,
      },
      lines: [],
      coaLines: [],
      coaOptions: [],
      withholdingTax: "-",
      immutable: true,
      option: {
        customer: [],
        territory: [],
        operatingUnit: [],
        cnPromotionType: [],
        costCenter: [],
        debitNote: [],
        withHoldingTax: [],
        shipTo: [],
        coa: [],
        territoryCoa: [],
        ownership: [
          { id: 0, name: "Operating Unit" },
          { id: 1, name: "Head Office" },
        ],
        tax: [],
      },
      customers: [],
      operatingUnits: [],
      selectedCustomer: null,
      debounceFunction: null,
      promoRate: 0,
      isLoading: false,
      isEdit: false,
      deletedAttachmentID: [],
      viewOnly: false,
      pphTotalValue: 0,
      showApprovalPrompt: false,
      note_reversal: "",
      vatRate: 0,
      cnCodePopup: false,
      createdCodes: "",
      invoices: [],
      createdInvoiceCodes: "",
      isCLoseWithButton: false,
      HO_TERRITORY_ID: 81,
    };
  },
  async mounted() {
    // await this.getOptionOU();
    this.$validator.attach({
      name: "Total PPh",
      rules: this.pphValidation,
      scope: "__global__",
    });

    await this.getGeneralForm();
    await this.getOptionCoa();
    await this.getTax();
    this.option.operatingUnit = [
      ...this.$store.state.operatingUnit.filter.operatingUnit,
    ];
    this.operatingUnits = [
      ...this.$store.state.operatingUnit.filter.operatingUnit,
    ];
    this.option.territory = [
      ...this.$store.state.operatingUnit.filter.territory_single,
    ];
    this.option.territoryCoa = [
      ...this.$store.state.operatingUnit.filter.territory_single,
    ];

    const path = this.$router.currentRoute.path.toLowerCase();
    this.isEdit = path.includes("/edit");
    if (path.includes("view")) {
      this.viewOnly = true;
    }
    if (this.isEdit || this.viewOnly) {
      this.isLoading = true;
      const param = atob(this.$route.params.ID);
      const id = parseInt(param);
      await this.getCreditNote(id);
      await this.getCustomer({
        search: this.creditNote.CustomerCode
          ? this.creditNote.CustomerCode
          : "",
        is_with_category: true,
      });
      await this.getDebitNote(null, this.creditNote.DebitNoteID);
      this.create.customer = this.option.customer.find((v) => {
        return v.ID === this.creditNote.CustomerID;
      });
      this.selectedCustomer = this.customers.find((v) => {
        return (
          v.customer.ID === this.create.customer.ID &&
          v.customer.code === this.create.customer.code &&
          v.customer.name === this.create.customer.name
        );
      });
      this.option.shipTo = this.selectedCustomer.customer_address;
      this.withholdingTax = this.option.withHoldingTax.find((v) => {
        return (
          v.customer_category_id ===
          this.selectedCustomer.customer.customer_category_id
        );
      }).calculation_method;
      console.log(this.withholdingTax, "this.withholdingTax");
      this.create.territory = this.option.territory.find((v) => {
        return v.TerritoryID === this.creditNote.TerritoryID;
      });
      this.create.operatingUnit = this.option.operatingUnit.find((v) => {
        return v.ID === this.creditNote.OperatingUnitID;
      });
      // this.create.cnPromotionType = this.option.cnPromotionType.find((v) => {
      //   return v.ID === this.creditNote.CnPromoTypeID;
      // });
      this.create.debitNote = this.option.debitNote.find((v) => {
        return v.id === this.creditNote.DebitNoteID;
      });
      // this.create.costCenter = this.option.costCenter.find((v) => {
      //   return v.ID === this.creditNote.CostCenterID;
      // });
      this.create.ownership = this.option.ownership.find((v) => {
        return (v.name = this.creditNote.Info.ownership);
      });
      this.create.notes = this.creditNote.Note;
      this.create.id = this.creditNote.ID;
      this.create.cnDate = moment(this.creditNote.Date).format("YYYY-MM-DD");
      this.create.cnPostingDate = moment(this.creditNote.PostingDate).format(
        "YYYY-MM-DD"
      );
      this.create.taxReturnDate = moment(this.creditNote.TaxReturnDate).format(
        "YYYY-MM-DD"
      );
      this.create.tax = this.option.tax.find((v) => {
        return v.ID === this.creditNote.Info.tax_id;
      });
      this.create.taxReturnNumber = this.creditNote.TaxReturnNumber;
      this.create.skb = this.creditNote.Info.is_skb ? "true" : "false";
      this.create.amount = this.priceFormat(this.creditNote.TotalValue);
      this.create.customer_code = this.creditNote.CustomerCode;
      this.fileAttachment = [];
      if (this.creditNote.Attachment && this.creditNote.Attachment.length > 0) {
        this.fileAttachment = this.creditNote.Attachment.map((v) => {
          return {
            ListID: v.ListID,
            NameFile: v.NameFile,
            PathFile: v.PathFile,
          };
        });
      }
      this.create.isHO = this.creditNote.Info.is_ho;

      this.$nextTick(function () {
        if (this.creditNote.TaxValue > 0) {
          this.create.isVat = true;
          this.vatRate = this.option.tax.find((v) => {
            return v.ID === this.creditNote.Info.tax_id;
          }).Rate;
        }

        this.lines = this.creditNote.Info.lines.map((v) => {
          const promo = this.option.cnPromotionType.find((x) => {
            return x.ID === v.promotion_type_id;
          });
          const matchPromo = promo.RateOption.find((v) => {
            return (
              v.NpwpType.toLowerCase() ===
              this.selectedCustomer.customer.npwp_type.toLowerCase()
            );
          });
          return {
            ship_to: this.option.shipTo.find((x) => {
              return x.ID === v.ship_to_id;
            }),
            promotion_type: this.option.cnPromotionType.find((x) => {
              return x.ID === v.promotion_type_id;
            }),
            dpp: this.priceFormat(v.dpp),
            vat: this.priceFormat(v.vat),
            withholding_tax: this.priceFormat(v.withholding_tax_value),
            text: v.text,
            assignment: v.assignment,
            value: this.priceFormat(v.value),
            promo_rate: matchPromo ? matchPromo.Rate : 0,
          };
        });
        this.coaLines = this.creditNote.Info.coa.map((v) => {
          return {
            coa: {
              ID: v.coa_id,
              Code: v.coa_code,
              Name: v.coa_name,
            },
            cost_center: this.option.costCenter.find((x) => {
              return x.ID === v.cost_center_id;
            }),
            territory: this.option.territoryCoa.find((x) => {
              return x.TerritoryID === v.territory_id;
            }),
            value: this.priceFormatWithMinus(v.value),
          };
        });

        this.$nextTick(function () {
          this.create.vatTotal = this.priceFormat(this.creditNote.TaxValue);
          this.isLoading = false;
        });
      });

      // console.log(this.lines, "this.lines");
    } else {
      this.create.taxReturnDate = moment(new Date()).format("YYYY-MM-DD");
      this.create.cnDate = moment(new Date()).format("YYYY-MM-DD");
      this.create.cnPostingDate = moment(new Date()).format("YYYY-MM-DD");

      const line = {
        ship_to: null,
        promotion_type: null,
        dpp: this.formatPrice(0),
        vat: this.formatPrice(0),
        withholding_tax: this.formatPrice(0),
        text: "",
        assignment: "",
        value: this.formatPrice(0),
        promo_rate: 0,
      };
      this.lines.push(line);
    }
  },
  created() {
    this.$validator.extend("equalToCnTotal", {
      validate: (value) => {
        const valFloat = parseFloat(value.toString().replace(/,/g, ""));
        const cnTotal = parseFloat(
          this.create.amount.toString().replace(/,/g, "")
        );
        if (valFloat === cnTotal) {
          return true;
        }
        return false;
      },
      getMessage: () => "Total detail value must be equal to total CN Value",
    });

    this.$validator.extend("notZero", {
      validate: (value) => {
        const valFloat = parseFloat(value.toString().replace(/,/g, ""));
        if (valFloat > 0 && !isNaN(valFloat)) {
          return true;
        }
        return false;
      },
      getMessage: () => "Value cannot be zero",
    });
  },
  watch: {
    "create.customer"() {
      if (this.isEdit || this.viewOnly) {
        return;
      }

      console.log(this.create.customer, "create.customer");
      console.log(this.customers, "this.customers");

      if (!this.create.customer) {
        this.selectedCustomer = null;
        this.create.territory = null;
        this.create.operatingUnit = null;
        this.option.operatingUnit = this.operatingUnits;
        this.lines = [...Array(this.lines.length)].map(() => {
          return {
            ship_to: null,
            promotion_type: null,
            dpp: this.formatPrice(0),
            vat: this.formatPrice(0),
            withholding_tax: this.formatPrice(0),
            text: "",
            assignment: "",
            value: this.formatPrice(0),
            promo_rate: 0,
          };
        });
        this.option.shipTo = [];
        this.withholdingTax = "";
      } else {
        this.selectedCustomer = this.customers.find((v) => {
          return (
            v.customer.ID === this.create.customer.ID &&
            v.customer.code === this.create.customer.code &&
            v.customer.name === this.create.customer.name
          );
        });

        this.option.shipTo = this.selectedCustomer.customer_address.filter(
          (v) => {
            return v.status.includes("Shipping Address");
          }
        );

        const billTo = this.selectedCustomer.customer_address.find((v) => {
          return v.status.includes("Billing Address");
        });

        if (billTo) {
          this.create.operatingUnit = this.option.operatingUnit.find((v) => {
            return v.TerritoryID === billTo.territory_id;
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "Billing address not found",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }

        if (this.selectedCustomer.customer_category.name === "GT") {
          this.option.territory = this.operatingUnits.filter((v) => {
            return (
              v.TerritoryID ===
              this.selectedCustomer.customer_address[0].territory_id
            );
          });

          this.create.ownership = this.option.ownership.find((v) => {
            return v.name === "Operating Unit";
          });

          this.create.territory = this.option.territory.find((v) => {
            return (
              v.TerritoryID ===
              this.selectedCustomer.customer_address[0].territory_id
            );
          });
        } else {
          if (!this.create.territory) {
            return;
          }
          this.option.territory = [
            ...this.$store.state.operatingUnit.filter.territory_single,
          ];
          this.create.territory = null;
        }

        this.lines.forEach((_, i) => {
          this.lines[i].ship_to = null;
        });

        this.withholdingTax = this.option.withHoldingTax.find((v) => {
          return (
            v.customer_category_id ===
            this.selectedCustomer.customer.customer_category_id
          );
        }).calculation_method;
      }
    },
    "create.territory": function () {
      if (!this.create.territory) {
        this.coaLines.forEach((_, i) => {
          this.coaLines[i].territory = null;
        });
        return;
      }

      this.option.territoryCoa = this.option.territory.filter((v) => {
        return v.TerritoryID === this.create.territory.TerritoryID;
      });

      this.coaLines.forEach((_, i) => {
        this.coaLines[i].territory = null;
      });

      this.lines.forEach((_, i) => {
        this.lines[i].ship_to = null;
      });

      this.option.shipTo = this.selectedCustomer.customer_address.filter(
        (v) => {
          return (
            v.status.includes("Shipping Address") &&
            v.territory_id === this.create.territory.TerritoryID
          );
        }
      );
    },

    "create.isVat"() {
      if (this.isLoading) {
        return;
      }
      if (!this.create.isVat) {
        this.create.tax = null;
        this.vatRate = 0;
        this.lines.forEach((v, i) => {
          this.lines[i].vat = this.formatPrice(0);
          this.onDppChange(this.lines[i].dpp, i);
        });
      }
    },

    "create.tax"() {
      if (this.isLoading) {
        return;
      }

      if (!this.create.tax) {
        this.vatRate = 0;
      } else {
        this.vatRate = this.create.tax.Rate;
      }

      this.lines.forEach((v, i) => {
        this.onDppChange(v.dpp, i);
      });
    },
    cnCodePopup() {
      if (!this.cnCodePopup && this.isCLoseWithButton) {
        this.isCLoseWithButton = false;
        this.cnCodePopup = false;
        this.navigateBack();
      } else {
        this.cnCodePopup = true;
      }
    },
    "create.amount"() {
      if (this.isLoading) {
        return;
      }
      this.$validator.validate("Total Detail Value");
    },
    // "create.ownership"() {},
  },
  computed: {
    pphTotal() {
      const total = this.lines.reduce((acc, val) => {
        return (
          parseFloat(acc) +
          parseFloat(
            val.withholding_tax
              ? val.withholding_tax.toString().replace(/,/g, "")
              : "0"
          )
        );
      }, 0);

      return this.priceFormat(total);
    },
    vatTotal() {
      const total = this.lines.reduce((acc, val) => {
        return (
          parseFloat(acc) +
          parseFloat(val.vat ? val.vat.toString().replace(/,/g, "") : "0")
        );
      }, 0);
      return this.priceFormat(total);
    },
    cnDetailValueTotal() {
      // const total = this.lines.reduce((acc, val) => {
      //   return (
      //     parseFloat(acc) +
      //     parseFloat(val.value ? val.value.toString().replace(/,/g, "") : "0")
      //   );
      // }, 0);

      const dppTotal = this.lines.reduce((acc, val) => {
        return (
          parseFloat(acc) +
          parseFloat(val.dpp ? val.dpp.toString().replace(/,/g, "") : "0")
        );
      }, 0);

      const coaTotal = this.coaLines.reduce((acc, val) => {
        return (
          parseFloat(acc) +
          parseFloat(val.value ? val.value.toString().replace(/,/g, "") : "0")
        );
      }, 0);

      const vatTotal = parseFloat(
        this.create.vatTotal
          ? this.create.vatTotal.toString().replace(/,/g, "")
          : 0
      );
      return this.priceFormat(dppTotal + vatTotal + coaTotal);
    },
    differenceOfDetailAndCnValue() {
      return this.priceFormatWithMinus(
        parseFloat(this.create.amount.toString().replace(/,/g, "")) -
          parseFloat(this.cnDetailValueTotal.toString().replace(/,/g, ""))
      );
    },
  },
  methods: {
    handleRate() {
      if (!this.create.isVat) {
        return;
      }

      if (!this.create.customer || !this.selectedCustomer) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select customer first",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      if (!this.create.customer.npwp_type) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Customer tax type is not registered",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      if (!this.create.cnPromotionType) {
        // this.$vs.notify({
        //   color: "danger",
        //   title: "Error",
        //   text: "Please select promotion type first",
        //   position: "top-right",
        //   iconPack: "feather",
        //   icon: "icon-x-circle",
        // });
        return;
      }

      const rateOption = this.create.cnPromotionType.RateOption.find((v) => {
        return v.NpwpType == this.create.customer.npwp_type;
      });

      if (!rateOption) {
        this.create.withholdingTaxValue = 0;
        this.create.amount = 0;
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Rate not found",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      this.promoRate = rateOption.Rate;

      if (!this.promoRate) {
        this.create.withholdingTaxValue = 0;
        this.create.amount = 0;
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Rate not found",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      if (this.withholdingTax == "GROSS") {
        this.create.amount =
          parseFloat(this.create.dpp.toString().replace(/,/g, "")) +
          parseFloat(this.create.vatInValue.toString().replace(/,/g, ""));
      } else {
        this.create.amount =
          parseFloat(this.create.dpp.toString().replace(/,/g, "")) +
          parseFloat(this.create.vatInValue.toString().replace(/,/g, "")) -
          parseFloat(
            this.create.withholdingTaxValue.toString().replace(/,/g, "")
          );
      }
      this.create.amount = this.formatPrice(
        this.create.amount.toString().replace(/,/g, "")
      );
      this.create.withholdingTaxValue = this.formatPrice(
        this.create.withholdingTaxValue.toString().replace(/,/g, "")
      );
    },
    paramUpload(array) {
      var form = new FormData();
      form.append("id", this.selected.ID);
      form.append("file", this.file[array]);
      return form;
    },
    uploadAttachment(array) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/credit-note/upload-file", this.paramUpload(array), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.fileAttachment = [];
          resp.data.attachment.map((v) => {
            this.fileAttachment.push({
              ListID: v.ListID,
              NameFile: v.NameFile,
              PathFile: v.PathFile,
            });
          });
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleShow(file) {
      console.log(file, "file");
      if (file.path != "" && file.path !== undefined) {
        this.downloadFileAwsS3(file.path);
      } else if (file.PathFile != "" && file.PathFile !== undefined) {
        this.downloadFileAwsS3(file.PathFile);
      } else {
        let objectURL;
        console.log(file);
        objectURL = URL.createObjectURL(file.File);

        // link.download = file.name; // this name is used when the user downloads the file
        var href = objectURL;
        window.open(href, "_blank").focus();
      }
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        // this.fileAttachment = [];
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleDeleteAttachment(index) {
      const path = this.$router.currentRoute.path.toLowerCase();
      if (this.fileAttachment[index].ListID && path.includes("/edit")) {
        this.deletedAttachmentID.push(this.fileAttachment[index].ListID);
      }

      this.fileAttachment = this.fileAttachment.filter((r, i) => {
        return i != index;
      });
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    isNumberAndFormat: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode != 8 && this.create.taxReturnNumber != null) {
        if (this.create.taxReturnNumber.length == 3) {
          this.create.taxReturnNumber = this.create.taxReturnNumber + ".";
        } else if (this.create.taxReturnNumber.length == 7) {
          this.create.taxReturnNumber = this.create.taxReturnNumber + "-";
        } else if (this.create.taxReturnNumber.length == 10) {
          this.create.taxReturnNumber = this.create.taxReturnNumber + ".";
        } else if (this.create.taxReturnNumber.length > 18) {
          evt.preventDefault();
        }
      }
    },
    async getOptionOU() {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/master/operating-unit");
        this.option.territory = [];
        this.option.operatingUnit = [];

        if (resp.code <= 299) {
          resp.data.records.forEach((r) => {
            let array = {
              operating_unit_id: r.ID,
              operating_unit_code: r.Code,
              operating_unit_name: r.Name,
              territory_id: r.OperatingUnitTerritory.TerritoryID,
              territory_code: r.OperatingUnitTerritory.Territory.code,
              territory_name: r.OperatingUnitTerritory.Territory.name,
            };
            this.option.territory.push(array);
            this.option.operatingUnit.push(array);
            this.operatingUnits.push(array);
          });
          this.immutable = false;
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.$vs.loading.close();
      }
    },

    validate() {
      const isValid = false;
      if (!this.create.customer) {
        this.$vs.notify({
          title: "Error",
          text: "Please select customer.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return isValid;
      }
      if (this.create.territory == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select territory",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return isValid;
      }

      if (this.create.operatingUnit == null) {
        this.$vs.notify({
          title: "Error",
          text: "Please select operating unit",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return isValid;
      }

      if (this.create.isVat == 1 && this.create.taxReturnNumber == "") {
        this.$vs.notify({
          title: "Error",
          text: "Tax return number is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return isValid;
      }

      if (this.create.isVat == 1 && this.create.taxReturnDate == "") {
        this.$vs.notify({
          title: "Error",
          text: "Tax return date is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return isValid;
      }

      const amount = parseFloat(
        this.create.amount.toString().replace(/,/g, "").toString()
      );

      if (amount === 0) {
        this.$vs.notify({
          title: "Error",
          text: "Amount is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return isValid;
      }

      if (isNaN(amount)) {
        this.$vs.notify({
          title: "Error",
          text: "Amount is invalid",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return isValid;
      }

      const pphTotal = parseFloat(
        this.pphTotal.toString().replace(/,/g, "").toString()
      );

      if (pphTotal === 0) {
        this.$vs.notify({
          title: "Error",
          text: "PPh Total Value is required",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return isValid;
      }

      if (isNaN(pphTotal)) {
        this.$vs.notify({
          title: "Error",
          text: "PPh Total Value is invalid",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return isValid;
      }

      if (pphTotal !== amount) {
        this.$vs.notify({
          title: "Error",
          text: "PPh Total Value must be equal to CN Value",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return isValid;
      }

      for (let i = 0; i < this.lines.length; i++) {
        const dpp = parseFloat(
          this.lines[i].dpp.toString().replace(/,/g, "").toString()
        );

        if (dpp === 0) {
          this.$vs.notify({
            title: "Error",
            text: "DPP is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }

        if (isNaN(dpp)) {
          this.$vs.notify({
            title: "Error",
            text: "DPP is invalid",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }

        const vat = parseFloat(
          this.lines[i].vat.toString().replace(/,/g, "").toString()
        );
        if (this.create.isVat === 1 && vat === 0) {
          this.$vs.notify({
            title: "Error",
            text: "VAT is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }

        if (this.create.isVat === 1 && isNaN(vat)) {
          this.$vs.notify({
            title: "Error",
            text: "VAT is invalid",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }

        const whTax = parseFloat(
          this.lines[i].withholding_tax.toString().replace(/,/g, "").toString()
        );

        if (whTax === 0) {
          this.$vs.notify({
            title: "Error",
            text: "Withholding Tax required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }

        if (isNaN(whTax)) {
          this.$vs.notify({
            title: "Error",
            text: "Withholding Tax is invalid",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }

        if (!this.lines[i].text) {
          this.$vs.notify({
            title: "Error",
            text: "Text is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }

        if (!this.lines[i].assignment) {
          this.$vs.notify({
            title: "Error",
            text: "Assignment is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }
      }

      if (this.coaLines.length === 0) {
        return !isValid;
      }

      for (let i = 0; i < this.coaLines.length; i++) {
        if (!this.coaLines[i].coa) {
          this.$vs.notify({
            title: "Error",
            text: "COA is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }

        const value = parseFloat(
          this.coaLines[i].value.toString().replace(/,/g, "").toString()
        );

        if (value === 0) {
          this.$vs.notify({
            title: "Error",
            text: "COA value is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }

        if (isNaN(value)) {
          this.$vs.notify({
            title: "Error",
            text: "Value is invalid",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }

        if (!this.coaLines[i].territory) {
          this.$vs.notify({
            title: "Error",
            text: "Territory is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }

        if (!this.coaLines[i].cost_center) {
          this.$vs.notify({
            title: "Error",
            text: "Cost center Unit is required",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return isValid;
        }
      }

      return !isValid;
    },

    handleSubmit(isWithReleased = false) {
      this.$validator.validateAll().then((result) => {
        let dppTotal = 0;

        if (result) {
          const lines = this.lines.map((v) => {
            dppTotal += parseFloat(
              v.dpp.toString().replace(/,/g, "").toString()
            );
            return {
              ship_to_id: v.ship_to ? v.ship_to.ID : null,
              promotion_type_id: v.promotion_type ? v.promotion_type.ID : null,
              dpp: parseFloat(v.dpp.toString().replace(/,/g, "").toString()),
              vat: parseFloat(v.vat.toString().replace(/,/g, "").toString()),
              withholding_tax_value: parseFloat(
                v.withholding_tax.toString().replace(/,/g, "").toString()
              ),
              withholding_tax_calculation: this.withholdingTax,
              text: v.text,
              assignment: v.assignment,
              value: parseFloat(
                v.value.toString().replace(/,/g, "").toString()
              ),
              mapping_gl: v.promotion_type ? v.promotion_type.MappingGl : null,
              promo_rate: v.promo_rate,
            };
          });

          const coas = this.coaLines.map((v) => {
            return {
              coa_id: v.coa.ID,
              coa_name: v.coa.Name,
              coa_code: v.coa.Code,
              territory_id: v.territory.TerritoryID,
              cost_center_id: v.cost_center.ID,
              cost_center_name: v.cost_center.Name,
              cost_center_code: v.cost_center.Code,
              value: parseFloat(
                v.value.toString().replace(/,/g, "").toString()
              ),
            };
          });

          const request = {
            id: this.create.id,
            customer_id: this.create.customer.ID,
            customer_name: this.create.customer.name,
            customer_code: this.create.customer.code,
            territory_id: this.create.territory.TerritoryID,
            operating_unit_id: this.create.operatingUnit.ID,
            debit_note_id: this.create.debitNote
              ? this.create.debitNote.id
              : null,
            cn_posting_date: moment(this.create.cnPostingDate).format(
              "YYYY-MM-DD"
            ),
            cn_date: moment(this.create.cnDate).format("YYYY-MM-DD"),
            notes: this.create.notes,
            tax_return_date: moment(this.create.taxReturnDate).format(
              "YYYY-MM-DD"
            ),
            tax_return_number: this.create.taxReturnNumber,
            amount: parseFloat(
              this.create.amount.toString().replace(/,/g, "").toString()
            ),
            tax_id: this.create.tax ? this.create.tax.ID : null,
            deleted_attachment_id:
              this.deletedAttachmentID.length > 0
                ? this.deletedAttachmentID
                : undefined,
            ownership: this.create.ownership.name,
            lines,
            coas,
            withholding_tax_calculation: this.withholdingTax,
            dpp_total: dppTotal,
            withholding_tax_value_total: parseFloat(
              this.pphTotal.toString().replace(/,/g, "").toString()
            ),
            vat_total: parseFloat(
              this.create.vatTotal.toString().replace(/,/g, "")
            ),
            is_with_released: isWithReleased,
            is_skb: this.create.skb === "true" ? true : false,
            is_ho: this.create.isHO,
          };

          const form = new FormData();
          form.append("data", JSON.stringify(request));

          const path = this.$router.currentRoute.path.toLowerCase();

          if (path.includes("/edit")) {
            console.log("FILE EDIT gESSS");
            if (this.fileAttachment.length > 0) {
              this.fileAttachment.forEach((v) => {
                if (v.File) {
                  form.append("file", v.File);
                }
              });
            }
          } else {
            if (this.fileAttachment.length > 0) {
              this.fileAttachment.forEach((v) => {
                form.append("file", v.File);
              });
            }
          }

          console.log(this.create);
          console.log(request);
          const bypass = false;
          if (bypass) {
            return;
          }
          this.$vs.loading();
          const post = createCreditNoteManual(form);
          post
            .then((r) => {
              if (r.code == 500) {
                this.$vs.notify({
                  title: "Failed",
                  text: r.message,
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-alert-circle",
                  color: "danger",
                });
              } else if (r.code == 200) {
                this.$vs.notify({
                  title: "Success",
                  text: r.message,
                  iconPack: "feather",
                  position: "top-right",
                  icon: "icon-alert-circle",
                  color: "success",
                });
                if (isWithReleased) {
                  // if (!this.isEdit) {
                  this.createdCodes = r.data.creditNote.Code;
                  // }

                  if (r.data.invoices.length > 0) {
                    const temp = r.data.invoices.map((v) => {
                      return v.code;
                    });
                    this.createdInvoiceCodes = temp.join(", ");
                  }
                  this.cnCodePopup = true;
                } else {
                  this.navigateBack();
                }
              }
              this.$vs.loading.close();
            })
            .catch((e) => {
              console.log(e);
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: e.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle",
              });
            });
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: "Some fields are still not valid. Please check again.",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
            time: 3000,
          });
        }
      });
    },
    async getCreditNote(id) {
      try {
        this.$vs.loading();
        const resp = await this.$http.get(`/api/v1/credit-note/data/${id}`);
        if (resp.code < 299) {
          this.creditNote = resp.data.creditNote;
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: "Failed to fetch credit note",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "Failed to fetch credit note",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    navigateBack() {
      this.$router.push({ name: "credit-note" });
    },
    async getGeneralForm() {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/credit-note/form-general");
        this.option.cnPromotionType = resp.data.creditNotePromotionType;
        this.option.costCenter = resp.data.costCenter;
        this.option.withHoldingTax = resp.data.withHoldingTaxs;

        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }
    },
    formatPrice(angka) {
      return angka
        .toString()
        .replace(/,/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    priceFormatWithMinus(amount) {
      const newAmount = amount
        .toString()
        .replace(/[^-.\d]/g, "")
        .toString();
      console.log(amount, newAmount);
      amount = parseFloat(newAmount).toFixed(2);

      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    onCustomerSearchChange(query = "") {
      if (query.length === 0) {
        return;
      }

      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }

      const params = {
        search: query ? query : "",
        is_with_category: true,
      };

      this.debounceFunction = debounce(() => {
        this.getCustomer(params);
      }, 500);

      this.debounceFunction();
    },
    async getCustomer(params) {
      try {
        this.$vs.loading();
        const resp = await getDataCustomer(params);
        if (resp.code <= 299) {
          this.option.customer = resp.data.map((row) => {
            return row.customer;
          });
          this.customers = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
    },
    onDebitNoteSearchChange(query = "") {
      if (query.length === 0) {
        return;
      }

      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }

      this.debounceFunction = debounce(() => {
        this.getDebitNote(query);
      }, 500);

      this.debounceFunction();
    },
    async getDebitNote(query, id = undefined) {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/debit-note/cn-creation", {
          params: {
            length: 20,
            page: 1,
            search: query,
            order: "desc",
            sort: "id",
            debit_note_id: id,
            // territory_id: this.$userLogin.territory_id,
            // territory_area_id: this.$userLogin.territory_area_id,
          },
        });

        if (resp.code < 299) {
          this.option.debitNote = resp.data;
          // this.selectedDebitNote = this.debitNoteOptions[0];
        }

        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: "Failed to fetch debit note options",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    async findDebitNote(id) {
      try {
        this.$vs.loading();
        const resp = await this.$http.get(
          "/api/v1/credit-note/find-debit-note/" + id
        );
        if (resp.code < 299) {
          this.create.debitNote = resp.data.debitNote;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Failed to fetch debit note",
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        }
        this.$vs.loading.close();
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "Failed to fetch debit note",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    async getTax(search = "") {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/master/tax/options", {
          params: {
            search,
            tax_group: "VAT",
            is_non_zero_rate: true,
          },
        });
        if (resp.code <= 299) {
          this.option.tax = resp.data;
          this.$vs.loading.close();
        }
      } catch (e) {
        console.log(e);
        this.$vs.loading.close();
      }
    },
    onTaxSearchChange(query = "") {
      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }

      this.debounceFunction = debounce(() => {
        this.getTax(query);
      }, 500);

      this.debounceFunction();
    },
    queryCustomer({ code, name }) {
      if (!code && !name) {
        return "Select customer";
      }
      return `(${code}), ${name}`;
    },
    queryDebitNote({ code }) {
      if (!code) {
        return "Select debit note";
      }
      return `${code}`;
    },
    onDppFieldBlur(i) {
      const temp = this.priceFormat(this.lines[i].dpp);
      this.lines[i].dpp = temp;
    },
    onVatFieldBlur() {
      this.create.vat = this.priceFormat(this.create.vat);
    },
    onCnValueBlur() {
      const temp = this.priceFormat(this.create.amount);
      this.create.amount = temp;
    },
    onCoaBlur(i) {
      const temp = this.priceFormatWithMinus(this.coaLines[i].value);
      this.coaLines[i].value = temp;
    },
    removeLine(index) {
      this.lines.splice(index, 1);
    },

    addLine() {
      this.lines.push({
        ship_to: null,
        promotion_type: null,
        dpp: this.formatPrice(0),
        // vat: this.formatPrice(0),
        withholding_tax: this.formatPrice(0),
        text: "",
        assignment: "",
        value: this.formatPrice(0),
        promo_rate: 0,
        vat: this.formatPrice(0),
      });
    },

    addCoaLine() {
      this.coaLines.push({
        coa: null,
        value: this.formatPrice(0),
        cost_center: null,
        territory: null,
      });
    },

    removeCoaLine(index) {
      this.coaLines.splice(index, 1);
    },

    onQueryCoa({ Code, Name }) {
      if (!Code || !Name) {
        return "";
      }
      return `(${Code}), ${Name}`;
    },

    onCoaSearchChanges(query) {
      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }
      this.debounceFunction = debounce(async () => {
        this.getOptionCoa(query);
      }, 800);
      this.debounceFunction();
    },

    async getOptionCoa(query = "") {
      try {
        this.$vs.loading();
        const resp = await this.$http.get("/api/v1/master/chart-of-account", {
          params: {
            order: "code",
            sort: "asc",
            search: query,
            length: 50,
          },
        });
        if (resp.code == 200) {
          this.option.coa = resp.data.records.map((v) => {
            return v.coa;
          });
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: resp.messsage,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        }
      } catch (e) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: e.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        console.log(e);
      }
    },
    QueryShipTo({ contact_name, code, address, district, sub_district, city }) {
      if (!contact_name || !code) {
        return "Select ship to";
      }
      return `(${code}) ${contact_name}\n${address}, ${sub_district}, ${district}, ${city}`;
    },
    queryTerritory({ TerritoryID, TerritoryName }) {
      if (!TerritoryID || !TerritoryName) {
        return "Select territory";
      }
      return `${TerritoryName}`;
    },
    queryOperatingUnit({ ID, Code, Name }) {
      if (!ID || !Code || !Name) {
        return "Select operating unit";
      }
      return `(${Code}) ${Name}`;
    },
    getTerritoryName(id) {
      if (!id) {
        return "";
      }
      const opt = [...this.$store.state.operatingUnit.filter.territory_single];
      const territory = opt.find((v) => {
        return v.TerritoryID === id;
      });
      return territory ? territory.TerritoryName : "";
    },
    handleRateChanges(promo, i) {
      if (this.isLoading || !this.lines[i].promotion_type) {
        this.lines[i].promotion_type = null;
        return;
      }

      if (!this.create.customer || !this.selectedCustomer) {
        this.lines[i].promotion_type = null;
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select customer first",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      const matchPromo = promo.RateOption.find((v) => {
        return (
          v.NpwpType.toLowerCase() ===
          this.selectedCustomer.customer.npwp_type.toLowerCase()
        );
      });

      if (matchPromo) {
        this.lines[i].promo_rate = matchPromo.Rate;
        // if (matchPromo.Rate === 0) {
        //   this.$vs.notify({
        //     color: "warning",
        //     title: "Warning",
        //     text: "Promo rate is zero",
        //     position: "top-right",
        //     iconPack: "feather",
        //     icon: "icon-x-circle",
        //     time: 4000,
        //   });
        // }
      } else {
        this.lines[i].promotion_type = null;
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Promo cannot be applied, please make sure the promotion has a matching customer tax type",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
          time: 4000,
        });
      }
    },
    onPromotionSelected(value, i) {
      this.handleRateChanges(value, i);
      this.onDppChange(this.lines[i].dpp, i);
    },
    onDppChange(value, i) {
      if (this.isLoading) {
        return;
      }
      if (!this.selectedCustomer) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select customer first",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      // if (this.lines[i].promo_rate === 0) {
      //   return;
      // }

      const dpp = parseFloat(value.toString().replace(/,/g, ""));

      const whTaxValue = Math.ceil(dpp * (this.lines[i].promo_rate / 100));
      console.log("whtax", whTaxValue);
      this.lines[i].withholding_tax = this.priceFormat(
        whTaxValue.toString().replace(/,/g, "")
      );

      if (this.create.isVat && !this.create.tax) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select tax rate",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      const vatLine = Math.floor((dpp * this.vatRate) / 100);
      this.lines[i].vat = this.priceFormat(
        vatLine.toString().replace(/,/g, "")
      );

      console.log(
        this.selectedCustomer.customer_category.name,
        this.lines[i].dpp,
        this.lines[i].withholding_tax,
        this.lines[i].vat,
        vatLine
      );
      this.lines[i].value = this.priceFormat(dpp + vatLine);
      this.create.vatTotal = this.vatTotal;
    },

    showErrorWithoutNumber(error) {
      if (error) {
        return error.replace(/[0-9]/g, "");
      } else {
        return null;
      }
    },

    handleReverse() {
      this.$vs.loading();
      this.$validator
        .validateAll()
        .then(() => {
          this.$http
            .patch(`api/v1/credit-note/update-status/${this.creditNote.ID}`, {
              status: 5, //waiting reverse
              // reversal_date: moment(this.reversalDate).format("YYYY-MM-DD"),
              reversal_note: this.note_reversal,
            })
            .then((result) => {
              console.log(result);
              this.$vs.loading.close();

              if (result.code == 200) {
                this.closeDetail();
                this.$vs.notify({
                  title: "Success",
                  text: "",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
                this.navigateBack();
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: result.message,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
              }
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: e.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            });
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        });
    },
    handleOpenPrompt() {
      this.showApprovalPrompt = true;
    },
    handleClose() {
      this.showApprovalPrompt = false;
    },
    onDppKeyUp(i) {
      const temp = this.formatPrice(this.lines[i].dpp);
      this.lines[i].dpp = temp;
    },
    onVatKeyUp() {
      this.create.vat = this.create.vat
        ? this.formatPrice(this.create.vat)
        : "";
    },
    onCnValueKeyUp() {
      const temp = this.formatPrice(this.create.amount);
      this.create.amount = temp;
    },
    onVatTotalKeyUp() {
      this.create.vatTotal = this.create.vatTotal
        ? this.formatPrice(this.create.vatTotal)
        : "";
    },
    onVatTotalBlur() {
      this.create.vatTotal = this.create.vatTotal
        ? this.formatPrice(this.create.vatTotal)
        : "";
    },
    close() {
      this.isCLoseWithButton = true;
      this.cnCodePopup = false;
    },
    async copyToClipboard(toCopy) {
      try {
        await navigator.clipboard.writeText(toCopy);
        this.$vs.notify({
          title: "Success",
          text: "Copied to clipboard",
          color: "success",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check-circle",
        });
      } catch (e) {
        console.log(e);
        this.$vs.notify({
          title: "Error",
          text: "Failed to copy to clipboard",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      }
    },
    onOwnershipSelected(value) {
      if (this.isLoading) {
        return;
      }
      this.option.operatingUnit = this.operatingUnits;
      this.option.territory = [
        ...this.$store.state.operatingUnit.filter.territory_single,
      ];

      if (value.name === "Head Office") {
        this.create.operatingUnit = this.option.operatingUnit.find((v) => {
          return v.TerritoryID === this.HO_TERRITORY_ID;
        });

        this.create.territory = this.option.territory.find((v) => {
          return v.TerritoryID === this.HO_TERRITORY_ID;
        });
      } else {
        if (this.create.territory.TerritoryID !== this.HO_TERRITORY_ID) {
          return;
        }
        this.create.territory = null;

        if (this.create.customer) {
          const billTo = this.selectedCustomer.customer_address.find((v) => {
            return v.status.includes("Billing Address");
          });

          if (billTo) {
            this.create.operatingUnit = this.option.operatingUnit.find((v) => {
              return v.TerritoryID === billTo.territory_id;
            });
          }
        } else {
          this.create.operatingUnit = null;
        }
      }
    },
    onHoChecboxChange(check) {
      if (!check) {
        return;
      }

      this.lines.forEach((_, i) => {
        this.lines[i].ship_to = null;
      });
    },
  },
};
</script>
